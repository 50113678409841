<div class="app-container">
  <!-- BG Effect -->
  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>

  <!-- Audio Container -->
  <div class="audio-container" hidden>
    <audio #audioElement (ended)="audioEnded()"></audio>
  </div>

  <!-- Container for left-aligned elements -->
  <div class="left-container">
    <!-- Main Content -->
    <div class="main-content">
      <!-- Welcome Message -->
      <ng-container *ngIf="currentState === 'welcome'">
        <div @fadeInOut class="welcome-message">
          <div class="center-text">
            <p class="welcome-message-animation">
              Say <span>“Hello!”</span> to Byte Butler
            </p>
            <p class="welcome-message-animation">
              Looking for something tasty?
            </p>
          </div>

          <!-- starter cards -->
          <div>
            <p class="suggestions-title">Some Suggestions</p>
            <ng-container *ngFor="let message of starterMessages">
              <div
                tabindex="-1"
                class="starter-card"
                (click)="setInputText(message)"
                (keydown)="setInputText(message)"
              >
                {{ message }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Chat history container -->
      <ng-container *ngIf="currentState !== 'welcome'">
        <div #chatHistoryContainer @fadeInOut class="chat-history">
          <ng-container *ngIf="chatHistory.length; else noChatHistory">
            <div
              *ngFor="let chat of chatHistory"
              [ngClass]="{
                message: true,
                receiver: chat.sender === 'server',
                sender: chat.sender === 'user'
              }"
            >
              {{ chat.message }}
            </div>
          </ng-container>

          <ng-template #noChatHistory>
            <span class="no-chat-history"> No Conversations! </span>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <!-- Controls -->
    <div class="control-block">
      <!-- Input area -->
      <ng-container
        *ngIf="!['listening', 'thinking', 'speaking'].includes(currentState)"
      >
        <div class="input-area">
          <div class="input-wrapper">
            <input
              class="input-box"
              type="text"
              placeholder="Type..."
              [(ngModel)]="inputText"
              (input)="checkInput()"
              (keydown.enter)="submitSpeech()"
            />

            <span class="underline"></span>

            <div class="input-controls">
              <button
                class="click-start-button input-button"
                type="button"
                (click)="startRecording()"
              >
                <span>Click to Start</span>
                <img
                  src="assets/images/svg/material-symbols_mic.svg"
                  width="18"
                  height="14"
                  alt="Mic Icon"
                />
              </button>

              <ng-container *ngIf="inputText.length">
                <button
                  type="submit"
                  (click)="submitSpeech()"
                  class="input-button send-button"
                  [class.show]="showSendButton"
                >
                  <img
                    src="assets/images/svg/material-symbols_send.svg"
                    width="18"
                    height="14"
                    alt="Send Icon"
                  />
                </button>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="hasChatHistory">
            <button
              (click)="toggleChat()"
              class="chat-button input-button chat-toggle-btn"
            >
              <span [class]="showChat ? 'button-text show' : 'button-text'">
                Close Chat
              </span>
              <span [class]="!showChat ? 'button-text show' : 'button-text'">
                Open Chat
              </span>

              <img
                src="assets/images/svg/chat-history.svg"
                width="38"
                height="38"
                alt="Chat History"
              />
            </button>
          </ng-container>
        </div>
      </ng-container>

      <!-- Speaking Block -->
      <ng-container *ngIf="currentState === 'speaking'">
        <div class="input-loader">
          <ul class="wave-menu">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </ng-container>

      <!-- Listening Block -->
      <ng-container
        *ngIf="currentState === 'listening' && currentInputMode === 'speech'"
      >
        <div class="input-loader center-text listening-block">
          <img src="assets/images/listening.gif" alt="Listening" />
          <span hidden>I’m Listening...</span>

          <button
            type="button"
            (click)="completeRecording()"
            class="stop-listening-block input-button"
          >
            <img
              class="mic-icon"
              src="assets/images/svg/mic-off-svgrepo-com.svg"
              width="18"
              height="14"
              alt="Mic Icon"
            />
          </button>
        </div>
      </ng-container>

      <!-- Thinking Block -->
      <ng-container *ngIf="currentState === 'thinking'">
        <div class="input-loader center-text thinking-block">
          <app-thinking-loader></app-thinking-loader>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Right Container -->
  <div class="right-container">
    <img
      class="haxi-logo"
      src="assets/images/svg/haxi_ai-logo.svg"
      width="110"
      height="24"
      alt="Send Icon"
    />
  </div>
</div>

<ng-container *ngIf="!isPermission && isActiveStream">
  <div class="permission-model">
    <div class="model-content">
      <p>
        Will you allow <span>{{ baseUrl }}</span> to use your microphone?
      </p>
      <img width="120" height="" src="assets/images/mic-animation.gif" alt="" />
      <p>Microphone to share:</p>
      <button class="common-button" (click)="getMicPermission()">Ok</button>
    </div>
  </div>
</ng-container>
