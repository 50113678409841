import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DELAY_TIME } from '@constants/common';
import {
  ChatMessage,
  ComponentState,
  TypeRes,
} from '@models/speech-recognition.model';
import { LoggerService } from '@services/logger.service';
import { StateManagementService } from '@services/state-management.service';
import { VoiceRecognitionService } from '@services/voice-recognition.service';
import { WebSocketService } from '@services/web-socket.service';
import { ThinkingLoaderComponent } from '@shared/thinking-loader/thinking-loader.component';
import { Subject, Subscription, delay, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-audio-chat',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgClass,
    NgForOf,
    ThinkingLoaderComponent,
    HttpClientModule,
  ],
  templateUrl: './audio-chat.component.html',
  styleUrls: ['./audio-chat.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('400ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '400ms ease-out',
          style({ opacity: 0, transform: 'translateY(-20px)' })
        ),
      ]),
    ]),
  ],
})
export class AudioChatComponent implements OnInit, OnDestroy {
  @ViewChild('audioElement') audioElement: ElementRef<HTMLAudioElement>;
  @ViewChild('chatHistoryContainer')
  private chatHistoryContainer: ElementRef<HTMLDivElement>;

  showWelcomeMessage = true;
  hasChatHistory = false;
  showChat = false;
  inputText = '';
  showSendButton = false;
  currentState: ComponentState = 'welcome';
  isPermission = false;
  isDecline = false;
  isActiveStream = true;
  isDisconnectAlert = false;
  answer = '';
  voiceInput = '';
  baseUrl = '';
  chatHistory: ChatMessage[] = [];
  messageIndex = 0;
  messages = ['Say “Hello!” to Byte Butler', 'Looking for something tasty?'];
  starterMessages = [
    "What's Today's Special?",
    'Do you have any dipping sauces for nuggets?',
  ];
  currentInputMode: 'text' | 'speech' = 'text';

  private destroy$ = new Subject<void>();
  private stateSub: Subscription;
  private intervalId: NodeJS.Timeout;

  constructor(
    private stateService: StateManagementService,
    private voiceRecognition: VoiceRecognitionService,
    private loggerService: LoggerService,
    private webSocketService: WebSocketService
  ) {
    // Connect to WebSocket for audio chat
    this.webSocketService.connect('audio');
  }

  ngOnInit(): void {
    this.baseUrl = window.location.origin;
    this.hasChatHistory = !!this.chatHistory.length;

    this.isPermission = JSON.parse(
      localStorage.getItem('userVisited') ?? 'false'
    );

    this.stateSub = this.stateService.viewState$.subscribe((state) => {
      this.currentState = state;
    });

    this.intervalId = setInterval(() => {
      this.messageIndex = (this.messageIndex + 1) % this.messages.length;
    }, DELAY_TIME['3000_MS']); // Change message every 4 seconds
  }

  private setTextInputMode() {
    this.currentInputMode = 'text';
  }

  private setSpeechInputMode() {
    this.currentInputMode = 'speech';
  }

  startRecording() {
    if (!this.isPermission) return;

    this.setSpeechInputMode();

    of({})
      .pipe(delay(DELAY_TIME['500_MS']))
      .subscribe(() => {
        this.startListening();
        this.voiceRecognition
          .startRecording()
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (blob) => {
              this.voiceRecognition
                .transcribeAudio(blob)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (text) => {
                    this.voiceInput = text.text;
                    if (this.voiceInput) {
                      this.submitSpeech();
                      this.stopRecording();
                    } else {
                      this.resetRecording();
                    }
                  },
                  error: (err) => {
                    this.loggerService.displayLog(err);
                    this.stopRecording();
                  },
                });
            },
            error: (err) => {
              this.loggerService.displayLog(err);
              this.stopRecording();
            },
          });
      });
  }

  submitSpeech() {
    if (!this.inputText.trim().length && !this.voiceInput.length) return;

    if (this.inputText.length) {
      this.setTextInputMode();
    } else {
      this.setSpeechInputMode();
    }

    const message = this.voiceInput || this.inputText;

    // Add outgoing message to chat history
    this.chatHistory.push({ sender: 'user', message: message });
    this.scrollToBottom();

    // Start thinking animation
    this.startThinking();

    // Send message to WS
    this.webSocketService.send({ question: message });

    // Listen for the response
    this.getSocketInformation();

    this.hasChatHistory = true;
    this.voiceInput = '';
    this.inputText = '';
  }

  startThinking() {
    this.stateService.setViewState('thinking');
  }

  getSocketInformation() {
    this.webSocketService.socket$.subscribe({
      next: (res: TypeRes) => {
        // Add response to chat history
        if (
          res.answer &&
          this.chatHistory[this.chatHistory.length - 1].message !== res.answer
        ) {
          this.chatHistory.push({ sender: 'server', message: res.answer });
          this.scrollToBottom();

          if (res.object_link) {
            this.playAudio(res.object_link);
          }
        }

        this.hasChatHistory = true;
        this.responseSubmitted();
      },
      error: (error) => {
        this.loggerService.displayLog(error);
      },
    });
  }

  playAudio(url: string) {
    const audio: HTMLAudioElement = this.audioElement.nativeElement;
    audio.src = url;
    audio.onended = () => {
      this.responseSubmitted(); // Handles post-audio activities
    };
    audio
      .play()
      .then(() => {
        this.stateService.setViewState('speaking');
      })
      .catch((err) => {
        this.loggerService.displayLog(err);
      });
  }

  audioEnded(): void {
    if (this.currentInputMode === 'speech') {
      this.startRecording();
    } else if (this.currentInputMode === 'text') {
      this.stateService.setViewState('none');
    } else {
      this.stateService.setViewState('none');
    }
  }

  responseSubmitted() {
    this.stateService.setViewState('none');

    if (this.hasChatHistory) this.toggleChat(); // Reopen chat history after thinking
  }

  stopRecording() {
    this.voiceInput?.length && this.showWelcome();

    this.voiceInput = '';

    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  completeRecording() {
    this.showWelcome();

    this.voiceInput = '';

    this.cancelOngoingRequests();
    this.voiceRecognition.cleanup();
  }

  cancelOngoingRequests() {
    this.destroy$.next();
  }

  resetRecording() {
    this.voiceRecognition.stopRecording();

    this.voiceInput = '';
    this.resetControls();

    // Start a new recording
    setTimeout(() => {
      this.startRecording();
    }); // Optional delay to ensure all states are reset
  }

  reloadModal() {
    location.reload();
  }

  preventContextMenu(event: Event): void {
    event.preventDefault();
  }

  stopRespond() {
    this.voiceInput = '';
    this.inputText = '';
    this.resetControls();
  }

  toggleChat(): void {
    this.showChat = !this.showChat;
    this.stateService.setViewState(
      this.currentState === 'chat' ? 'welcome' : 'chat'
    );

    this.scrollToBottom();
  }

  showWelcome() {
    this.stateService.setViewState('welcome');
  }

  startListening() {
    this.stateService.setViewState(
      this.currentState === 'listening' ? 'none' : 'listening'
    );
  }

  checkInput(): void {
    this.showSendButton = !!this.inputText.length;
  }

  shouldShowStopListening(): boolean {
    return ['listening', 'thinking'].includes(this.currentState);
  }

  getMicPermission() {
    navigator.mediaDevices
      .getUserMedia({
        audio: { noiseSuppression: true },
      })
      .then((stream) => {
        this.isActiveStream = stream.active;
        if (this.isActiveStream) {
          this.isPermission = true;
          this.reloadModal();
        }
        this.isDecline = false;
        localStorage.setItem('userVisited', JSON.stringify(this.isPermission));
      })
      .catch((error) => {
        this.isPermission = false;
        this.isDecline = true;
        this.loggerService.displayLog(error);
      });
  }

  resetControls() {
    this.stateService.setViewState('welcome');
    this.showChat = false;
  }

  setInputText(message: string): void {
    this.inputText = message;
    this.submitSpeech();
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.chatHistoryContainer?.nativeElement) {
        const element = this.chatHistoryContainer.nativeElement;
        element.scrollTop = element.scrollHeight;
      }
    }, DELAY_TIME['100_MS']); // A short delay to ensure DOM updates
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.destroy$.next();
    this.destroy$.complete();
    this.stateSub.unsubscribe();
    this.webSocketService.disconnect();
  }
}
