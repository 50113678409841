import { Injectable } from '@angular/core';
import { throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

import { DELAY_TIME, UUID_KEY_AUDIO, UUID_KEY_VIDEO } from '@constants/common';
import { environment } from '@environment/environment';
import { TypeRes } from '@models/speech-recognition.model';
import { LoggerService } from '@services/logger.service';
import { UserSessionService } from '@services/user-session.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket$: WebSocketSubject<TypeRes>;

  constructor(
    private loggerService: LoggerService,
    private userSessionService: UserSessionService
  ) {}

  connect(chatType: 'audio' | 'video') {
    const uuid = this.getChatUuid(chatType);
    this.socket$ = webSocket({
      url: `${environment.WS_URL}chat/${uuid}`,
      openObserver: {
        next: () => {
          this.loggerService.displayLog('WebSocket connected', 'log');
        },
      },
      closeObserver: {
        next: () => {
          this.loggerService.displayLog('WebSocket disconnected', 'error');
        },
      },
    });

    this.socket$
      .pipe(
        retry({
          delay: (_error, retryCount) => {
            this.loggerService.displayLog(`Retrying... (${retryCount})`, 'log');
            return timer(DELAY_TIME['10000_MS']);
          },
        }),
        catchError((err) => {
          this.loggerService.displayLog(err, 'error');
          return throwError(() => new Error(err));
        })
      )
      .subscribe();
  }

  private getChatUuid(chatType: 'audio' | 'video'): string | null {
    return chatType === 'audio'
      ? this.userSessionService.getUuid(UUID_KEY_AUDIO)
      : this.userSessionService.getUuid(UUID_KEY_VIDEO);
  }

  disconnect() {
    if (this.socket$) {
      this.socket$.complete();
    }
  }

  send(message: TypeRes) {
    if (this.socket$) {
      this.socket$.next(message);
    }
  }
}
