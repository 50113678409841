import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  displayLog(data: unknown, type: 'log' | 'error' | 'warn' = 'error') {
    // eslint-disable-next-line no-console
    console[type](data);
  }
}
