import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, Subscriber } from 'rxjs';

import { DELAY_TIME, MAGIC_NUMBERS, OpenAIConfig } from '@constants/common';
import { WhisperResponse } from '@models/common.model';

@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionService {
  private mediaRecorder: MediaRecorder;
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private audioChunks: BlobPart[] = [];
  private silenceTimer$: ReturnType<typeof setTimeout> | undefined;

  constructor(private http: HttpClient) {}

  startRecording(): Observable<Blob> {
    this.audioContext = new AudioContext();
    this.audioChunks = [];
    return new Observable<Blob>((observer) => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => this.setupRecorder(stream, observer))
        .catch((error) => observer.error(error));
    });
  }

  private setupRecorder(stream: MediaStream, observer: Subscriber<Blob>): void {
    if (!this.audioContext) {
      observer.error('Audio context is not initialized');
      return;
    }

    const source = this.audioContext.createMediaStreamSource(stream);
    this.analyser = this.audioContext.createAnalyser();
    source.connect(this.analyser);

    this.setupMediaRecorder(stream, observer);
    this.setupSilenceDetection();
  }

  private setupMediaRecorder(
    stream: MediaStream,
    observer: Subscriber<Blob>
  ): void {
    this.mediaRecorder = new MediaRecorder(stream);
    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      this.audioChunks.push(event.data);
    };
    this.mediaRecorder.onstop = () => {
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
      observer.next(audioBlob);
      observer.complete();
      this.sessionCleanup(stream);
    };
    this.mediaRecorder.start();
  }

  private setupSilenceDetection(): void {
    const dataArray = new Uint8Array(this.analyser!.frequencyBinCount);
    interval(DELAY_TIME['100_MS']).subscribe(() => {
      if (!this.analyser) return;
      this.analyser.getByteTimeDomainData(dataArray);
      this.evaluateSilence(dataArray);
    });
  }

  private evaluateSilence(dataArray: Uint8Array): void {
    const sum = dataArray.reduce(
      (acc, value) =>
        acc + (value - MAGIC_NUMBERS['128']) ** MAGIC_NUMBERS['2'],
      0
    );
    const average = Math.sqrt(sum / dataArray.length);
    if (average < MAGIC_NUMBERS['2']) {
      this.startSilenceTimer();
    } else {
      this.clearSilenceTimer();
    }
  }

  private startSilenceTimer(): void {
    if (!this.silenceTimer$) {
      this.silenceTimer$ = setTimeout(
        () => this.stopRecording(),
        DELAY_TIME['2000_MS']
      );
    }
  }

  private clearSilenceTimer(): void {
    if (this.silenceTimer$) {
      clearTimeout(this.silenceTimer$);
      this.silenceTimer$ = undefined;
    }
  }

  stopRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop(); // This will trigger onstop and clean up
    }
  }

  cleanup() {
    this.stopRecording();
    this.sessionCleanup(this.mediaRecorder.stream);
  }

  private sessionCleanup(stream: MediaStream): void {
    stream.getTracks().forEach((track) => track.stop());
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
      this.analyser = null;
    }
    this.clearSilenceTimer();
  }

  transcribeAudio(blob: Blob) {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('model', OpenAIConfig.model);
    formData.append('language', 'en');

    return this.http.post<WhisperResponse>(OpenAIConfig.baseUrl, formData, {
      headers: {
        Authorization: `Bearer ${OpenAIConfig.apiKey}`,
      },
    });
  }
}
