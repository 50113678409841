import { Injectable } from '@angular/core';
import {
  EXPIRY_DURATION,
  EXPIRY_KEY_AUDIO,
  EXPIRY_KEY_VIDEO,
  UUID_KEY_AUDIO,
  UUID_KEY_VIDEO,
} from '@constants/common';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  constructor() {
    this.initializeUuid(UUID_KEY_AUDIO, EXPIRY_KEY_AUDIO);
    this.initializeUuid(UUID_KEY_VIDEO, EXPIRY_KEY_VIDEO);
  }

  private initializeUuid(uuidKey: string, expiryKey: string): void {
    const currentUuid = this.getUuid(uuidKey);
    const expiryTime = this.getExpiryTime(expiryKey);

    if (!currentUuid || !expiryTime || Date.now() > expiryTime) {
      this.setNewUuid(uuidKey, expiryKey);
    }
  }

  private setNewUuid(uuidKey: string, expiryKey: string): void {
    const newUuid = uuidv4();
    const expiryTime = Date.now() + EXPIRY_DURATION;
    localStorage.setItem(uuidKey, newUuid);
    localStorage.setItem(expiryKey, expiryTime.toString());
  }

  getUuid(uuidKey: string): string | null {
    return localStorage.getItem(uuidKey);
  }

  private getExpiryTime(expiryKey: string): number | null {
    const expiryTime = localStorage.getItem(expiryKey);
    return expiryTime ? parseInt(expiryTime, 10) : null;
  }
}
