<div class="main-container">
  <div class="listening">
    <div class="img-box">
      <img src="assets/images/listening.gif" alt="Listening" />
    </div>
    <p>I’m Listening....</p>
  </div>

  <div class="redirecting-timer">
    Redirecting in<span class="timer">1:54</span>
  </div>

  <ng-container *ngIf="videoError; else displayVideo">
    <figure class="placeholder-img">
      <div class="placeholder-overlay"></div>

      <img
        class="placeholder-img"
        src="assets/images/vertical-env-bg.png"
        alt="Environment background"
      />

      <img
        class="placeholder-img persona-img"
        src="assets/images/persona.png"
        alt="Persona"
      />
    </figure>
  </ng-container>

  <ng-template #displayVideo>
    <video
      #videoElement
      (contextmenu)="preventRightClick($event)"
      src="assets/videos/vertical-video-placeholder.mp4"
      class="placeholder-video"
      alt="Input video"
    >
      Your browser does not support HTML5 video.
    </video>
  </ng-template>

  <div class="content">
    <!-- Main Content -->
    <div class="lower-container">
      <!-- Welcome Message Block -->
      <div class="welcome-text">
        <p class="title">Hi “ <span>I’m Haxi </span>” How can I assist you?</p>
      </div>

      <!-- Chat History Block -->
      <div class="chat-history">
        <div
          *ngFor="let chat of chatHistory"
          [ngClass]="{
            'user-message': chat.sender === 'user',
            'server-message': chat.sender === 'server'
          }"
        >
          <span class="sender">
            {{ chat.sender === "user" ? "You" : "Haxi" }}:
          </span>
          <span class="message">{{ chat.message }}</span>
        </div>
      </div>

      <!-- Suggestions Block -->
      <div class="suggestions">
        <p class="suggestions-title">Some Suggestions</p>

        <!-- Suggestion Cards -->
        <div class="suggestion-cards">
          <ng-container *ngFor="let card of starterCards">
            <ng-container *ngIf="card.showOnCheckout">
              <div class="card">
                <div class="card-header">
                  <img
                    [src]="card.iconSrc"
                    [alt]="card.alt"
                    width="20"
                    height="20"
                  />
                  <p>
                    {{ card.title }}
                  </p>
                </div>

                <div class="card-content">
                  <ng-container *ngIf="card.src && card.showOnCheckout">
                    <img
                      [src]="card.src"
                      [alt]="card.alt"
                      width="60"
                      height="60"
                    />
                  </ng-container>
                  <p>{{ card.description }}</p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Food Choices Block -->
      <div class="food-selection">
        <div class="food-choices">
          <div *ngFor="let choice of foodChoices" class="food-choice">
            <div class="food-image">
              <img
                [src]="choice.image"
                [alt]="choice.name"
                height="120"
                width="120"
              />
            </div>
            <p class="food-name">{{ choice.name }}</p>
          </div>
        </div>

        <div class="selection-info">
          <p>Select or tell me the option.</p>
        </div>
      </div>

      <!-- Food Item Details Block -->
      <div class="food-item-details">
        <div class="item-image">
          <img
            src="assets/images/food-items/aloo-tikki-burger.png"
            alt="McAloo Tikki Burger"
          />
        </div>

        <div class="food-item-info">
          <strong class="food-item-title"
            >McAloo Tikki Burger<sup>®</sup></strong
          >

          <div class="item-description-holder">
            <p class="food-item-description-heading">
              The one that never goes out of f(l)avour.
            </p>

            <p class="food-item-description">
              Regular bun crown, Tom-Mayo sauce, Sliced tomatoes, Shredded
              onion, Aloo tikki patty, Regular bun heel
            </p>

            <div class="food-item-nutrition">
              <p>Serving Size: <span>146g</span></p>
              <p>Energy: <span>339.56kCal</span></p>
            </div>
          </div>

          <div class="cart-action-holder">
            <button class="add-to-cart-button">ADD TO CART</button>
            <p class="food-item-instruction">“Say or click”</p>
          </div>
        </div>
      </div>

      <!-- Order Summary Block -->
      <div class="order-summary">
        <!-- Order Items List -->
        <div class="order-information">
          <div class="order-items-list">
            <table>
              <tr *ngFor="let item of orderItems; let i = index">
                <td>{{ i + 1 }}.</td>
                <td>
                  <img
                    [src]="item.image"
                    [alt]="item.name"
                    height="60"
                    width="60"
                  />
                </td>
                <td>{{ item.name }}</td>
                <td>x{{ item.quantity }}</td>
                <td>₹{{ item.price.toFixed(2) }}</td>
              </tr>
            </table>
          </div>

          <!-- Order Summary -->
          <div class="payment-summary">
            <h3 class="summary-title">Order Summary</h3>
            <p class="total-payable">
              Total Payable<span class="total-amount">₹1,642</span>
            </p>

            <hr class="custom-divider" />

            <div class="summary-details">
              <p class="detail">Sub Total <span>₹1,523.80</span></p>
              <p class="detail">Handling Charges <span>₹39.99</span></p>
              <p class="detail">Gov. Tax <span>₹78.20</span></p>
            </div>
          </div>
        </div>

        <p class="order-instruction">
          Select to customize or tell me what's in your mind.
        </p>

        <!-- Checkout Button -->
        <div class="checkout-button-holder">
          <button class="checkout-button">
            <img src="assets/images/svg/kiosk-checkout.svg" alt="Checkout" />
            “HAXI CHECKOUT MY ORDER”
          </button>
        </div>
      </div>

      <!-- Payment Block -->
      <div class="order-payment">
        <p class="total-payable-amount">
          Total Payable Amount <span class="amount">₹1,642</span>
        </p>
        <div class="qr-code-container">
          <img
            src="assets/images/placeholder-qr-code.png"
            alt="QR Code"
            class="qr-code-image"
          />
          <p class="payment-instruction">Proceed to payment</p>
          <p class="payment-methods">
            Scan the QR from your mobile using any UPI app such as PhonePe,
            Google Pay, Paytm, CRED, Amazon Pay, BHIM etc.
          </p>
        </div>
        <div class="progress-bar">
          <div class="progress" [style.width.%]="progress"></div>
        </div>
        <p class="payment-timer">
          Approve payment within
          <span class="timer">{{ minutes }}:{{ seconds }}</span>
        </p>
        <div class="cancel-order">
          <p>Do You Want to <span>Cancel</span> Your Order?</p>
        </div>
      </div>

      <!-- Thank You Block -->
      <div class="thank-you">
        <p class="thank-you-message">
          Thank you! Please collect your receipt and token number below and wait
          for it to be called
        </p>

        <p class="order-number-label">Your take away order number is</p>

        <div class="order-number-circle">
          <span class="order-number">170</span>
        </div>

        <p class="enjoy-message">Enjoy your meal!</p>

        <p class="receipt-reminder">Don't forget to take your receipt.</p>
      </div>
    </div>

    <!-- Footer Section -->
    <div class="footer-content">
      <!-- Order Listing Block -->
      <div class="order-listing">
        <p class="order-help">For order listing, Just say ““</p>
        <div class="cart-container">
          <span class="cart-block">
            <img
              src="assets/images/svg/lets-icons_bag.svg"
              width="36"
              height="36"
              alt="Bag"
            />
          </span>
          <div class="order-items">
            <div *ngFor="let item of orderItems" class="order-item">
              <img
                [src]="item.image"
                [alt]="item.name"
                class="order-item-image"
                width="24"
                height="24"
              />
              <span class="order-item-name">{{ item.name }}</span>
              <span class="order-item-quantity">x {{ item.quantity }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="logo-container">
        <img
          class="haxi-logo"
          src="assets/images/svg/haxi_ai-logo.svg"
          width="110"
          height="24"
          alt="Haxi Logo"
        />
      </div>
    </div>
  </div>
</div>
