<div class="video-container">
  <ng-container *ngIf="videoError; else displayVideo">
    <img class="placeholder-img" [src]="placeholder" alt="Environment" />
    <img
      class="placeholder-img"
      src="assets/images/bg-overlay.png"
      alt="Environment"
    />
  </ng-container>

  <ng-template #displayVideo>
    <video
      #videoElement
      autoplay
      muted
      [src]="videoSrc"
      class="placeholder-video"
      [attr.loop]="shouldLoop ? '' : null"
      (error)="onVideoError()"
      (ended)="onVideoEnded()"
    >
      Your browser does not support HTML5 video.
    </video>
  </ng-template>

  <!-- Container for left-aligned elements -->
  <div class="left-container">
    <div class="main-content">
      <ng-container *ngIf="currentState === 'welcome'">
        <!-- Welcome Message -->
        <div @fadeInOut class="welcome-message">
          <div class="center-text">
            Say <span>“Hello!”</span> to digital humans
          </div>
        </div>
      </ng-container>

      <!-- Chat history container -->
      <ng-container
        *ngIf="currentState === 'chat' || currentState === 'thinking'"
      >
        <!-- Chat history shows when chat is visible or history exists -->
        <div #chatHistoryContainer @fadeInOut class="chat-history">
          <div
            *ngFor="let chat of chatHistory"
            [ngClass]="{
              message: true,
              receiver: chat.sender === 'server',
              sender: chat.sender === 'user'
            }"
          >
            {{ chat.message }}
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="currentState === 'listening' && currentInputMode === 'speech'"
      >
        <div class="center-text listening-block" @fadeInOut>
          <img src="assets/images/listening.gif" alt="Listening" />
          <span>I’m Listening....</span>
        </div>
      </ng-container>

      <ng-container *ngIf="currentState === 'submitting'">
        <div class="center-text submitting-block" @fadeInOut>
          <img src="assets/images/submit.gif" alt="Submitting" />
          <span>Auto Submitting in {{ countdown }} sec.</span>
        </div>
      </ng-container>
    </div>

    <!-- stop listening block -->
    <div class="input-area">
      <ng-container
        *ngIf="
          shouldShowStopListening() && currentState !== 'thinking';
          else inputBlock
        "
      >
        <button
          type="button"
          (click)="completeRecording()"
          class="stop-listening-block input-button"
        >
          <span>Stop Listening</span>
          <img
            src="assets/images/svg/material-symbols_mic.svg"
            width="18"
            height="14"
            alt="Mic Icon"
          />
        </button>
      </ng-container>

      <ng-template #inputBlock>
        <ng-container *ngIf="currentState !== 'thinking'">
          <div class="input-wrapper">
            <input
              class="input-box"
              type="text"
              placeholder="Type..."
              [(ngModel)]="inputText"
              (input)="checkInput()"
              (keydown.enter)="submitSpeech()"
            />

            <span class="underline"></span>

            <div class="input-controls">
              <!-- Audio recorder button always visible -->
              <button
                class="input-button"
                type="button"
                (click)="startRecording()"
              >
                <span>Click to Start</span>
                <img
                  src="assets/images/svg/material-symbols_mic.svg"
                  width="18"
                  height="14"
                  alt="Mic Icon"
                />
              </button>

              <!-- Send button shown only when inputText is not empty -->
              <ng-container *ngIf="inputText.length">
                <button
                  type="submit"
                  (click)="submitSpeech()"
                  class="input-button send-button"
                  [class.show]="showSendButton"
                >
                  <img
                    src="assets/images/svg/material-symbols_send.svg"
                    width="18"
                    height="14"
                    alt="Send Icon"
                  />
                </button>
              </ng-container>
            </div>
          </div>

          <!-- Round button to open chat history -->
          <ng-container *ngIf="hasChatHistory">
            <button
              (click)="toggleChat()"
              class="chat-button input-button chat-toggle-btn"
            >
              <span [class]="showChat ? 'button-text show' : 'button-text'">
                Close Chat
              </span>
              <span [class]="!showChat ? 'button-text show' : 'button-text'">
                Open Chat
              </span>
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="right-container">
    <div>
      <ng-container *ngIf="currentState === 'thinking'">
        <div class="center-text thinking-block" @fadeInOut>
          <app-thinking-loader></app-thinking-loader>
        </div>
      </ng-container>
    </div>
    <div>
      <img
        class="haxi-logo"
        src="assets/images/svg/haxi_ai-logo.svg"
        width="110"
        height="24"
        alt="Send Icon"
      />
    </div>
  </div>
</div>

<ng-container *ngIf="!isPermission && isActiveStream">
  <div class="permission-model">
    <div class="model-content">
      <p>
        Will you allow <span>{{ baseUrl }}</span> to use your microphone?
      </p>
      <img width="120" height="" src="assets/images/mic-animation.gif" alt="" />
      <p>Microphone to share:</p>
      <button class="common-button" (click)="getMicPermission()">Ok</button>
    </div>
  </div>
</ng-container>
