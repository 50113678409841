import { Routes } from '@angular/router';
import { AudioChatComponent } from '@pages/audio-chat/audio-chat.component';
import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { VerticalKioskComponent } from '@pages/vertical-kiosk/vertical-kiosk.component';
import { VideoBgComponent } from '@pages/video-bg/video-bg.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: VideoBgComponent,
  },
  {
    path: 'audio-chat',
    component: AudioChatComponent,
  },
  {
    path: 'video-chat',
    component: VideoBgComponent,
  },
  {
    path: 'kiosk',
    component: VerticalKioskComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
    data: {
      title: 'Page Not Found',
    },
  },
];
