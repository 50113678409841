import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateManagementService {
  private viewState = new BehaviorSubject<
    | 'welcome'
    | 'chat'
    | 'listening'
    | 'submitting'
    | 'thinking'
    | 'speaking'
    | 'none'
  >('welcome');
  viewState$ = this.viewState.asObservable();

  constructor() {}

  setViewState(
    state:
      | 'welcome'
      | 'chat'
      | 'listening'
      | 'submitting'
      | 'thinking'
      | 'speaking'
      | 'none'
  ) {
    this.viewState.next(state);
  }
}
