/* eslint-disable no-magic-numbers */
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAGIC_NUMBERS } from '@app/core/constants/common';
import { ChatMessage } from '@app/core/models/speech-recognition.model';

import { LoggerService } from '@services/logger.service';

interface FoodChoices {
  name: string;
  image: string;
}

interface OrderItem {
  name: string;
  quantity: number;
  image: string;
  price: number;
}

@Component({
  selector: 'app-vertical-kiosk',
  standalone: true,
  imports: [NgIf, NgClass, NgForOf],
  templateUrl: './vertical-kiosk.component.html',
  styleUrls: ['./vertical-kiosk.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('400ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '400ms ease-out',
          style({ opacity: 0, transform: 'translateY(-20px)' })
        ),
      ]),
    ]),
  ],
})
export class VerticalKioskComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

  videoError = false;
  progress: number = 100;
  minutes: string = '10';
  seconds: string = '00';
  private countdownInterval: string | number | NodeJS.Timeout | undefined;

  chatHistory: ChatMessage[] = [
    { sender: 'server', message: '“I’m Haxi” I’ll take your orders.' },
    {
      sender: 'user',
      message: 'What burgers do you have?',
    },
    {
      sender: 'server',
      message: 'Today’s burger menu.',
    },
  ];

  starterCards = [
    {
      title: 'Add-On',
      description: 'Please add “Happy Meal” in my order listing.',
      iconSrc: 'assets/images/svg/add-on.svg',
      alt: 'Add-on',
      src: 'assets/images/food-items/happy-meal.png',
      showOnCheckout: true,
    },
    {
      title: 'Order',
      description: 'Can I build a custom burger with extra pickles?',
      iconSrc: 'assets/images/svg/food-pack.svg',
      alt: 'Food Pack',
      src: '',
      showOnCheckout: false,
    },
    {
      title: 'Menu',
      description: 'What kind of chicken sandwiches do you have?',
      iconSrc: 'assets/images/svg/menu.svg',
      alt: 'Menu',
      src: '',
      showOnCheckout: false,
    },
    {
      title: 'Customization',
      description: 'Do you have any dipping sauces for nuggets?',
      iconSrc: 'assets/images/svg/utensils.svg',
      alt: 'Customization',
      src: '',
      showOnCheckout: true,
    },
    {
      title: 'Nutritional Information',
      description: 'How many grams of protein are in a McChicken sandwich?',
      iconSrc: 'assets/images/svg/nutrition.svg',
      alt: 'Nutritional Information',
      src: '',
      showOnCheckout: false,
    },
    {
      title: 'Promotions & Deals',
      description: "What's the current McCafe promotion?",
      iconSrc: 'assets/images/svg/offers.svg',
      alt: 'Promotions & Deals',
      src: '',
      showOnCheckout: true,
    },
    {
      title: 'Restaurant Info',
      description: 'What are your store hours today?',
      iconSrc: 'assets/images/svg/outlet.svg',
      alt: 'Restaurant Info',
      src: '',
      showOnCheckout: false,
    },
  ];

  foodChoices: FoodChoices[] = [
    {
      name: 'Burgers Combos (Meals)',
      image: 'assets/images/food-items/burger-combo.png',
    },
    {
      name: 'Burgers & Wraps',
      image: 'assets/images/food-items/burger-wraps.png',
    },
    {
      name: 'Match Time Sharing Combos',
      image: 'assets/images/food-items/match-time-sharing-combo.png',
    },
    {
      name: 'Happy Meal',
      image: 'assets/images/food-items/happy-meal.png',
    },
    {
      name: 'WcDonald’s Combos',
      image: 'assets/images/food-items/wcdonald-combo.png',
    },
    {
      name: 'Fries & Sides',
      image: 'assets/images/food-items/fries-slides.png',
    },
    {
      name: 'Coffee & Beverages',
      image: 'assets/images/food-items/coffee-beverages.png',
    },
  ];

  orderItems: OrderItem[] = [
    {
      name: 'Mexican Veggie Wrap',
      quantity: 2,
      price: 379.04,
      image: 'assets/images/food-items/mexicanveggie-wrap.png',
    },
    {
      name: 'McCheese Burger Chicken',
      quantity: 2,
      price: 561.9,
      image: 'assets/images/food-items/cheese-burger-chicken.png',
    },
    {
      name: 'Mocha Coffee (R)',
      quantity: 2,
      price: 582.86,
      image: 'assets/images/food-items/mocha-coffee.png',
    },
  ];

  constructor(private loggerService: LoggerService) {}

  ngOnInit(): void {
    // this.startCountdown(180);
    this.loggerService.displayLog('Page loaded successfully', 'log');
  }

  ngAfterViewInit(): void {
    this.initializeVideoPlayback();
  }

  private startCountdown(duration: number): void {
    let timeRemaining = duration;
    this.countdownInterval = setInterval(() => {
      timeRemaining--;

      // Calculate minutes and seconds
      const minutes = Math.floor(timeRemaining / 60);
      const seconds = timeRemaining % 60;

      this.minutes = minutes.toString().padStart(2, '0');
      this.seconds = seconds.toString().padStart(2, '0');

      // Calculate progress percentage
      this.progress = (timeRemaining / duration) * 100;

      if (timeRemaining <= 0) {
        clearInterval(this.countdownInterval);
        this.progress = 0;
        this.minutes = '00';
        this.seconds = '00';
      }
    }, 1000); // Update every second
  }

  initializeVideoPlayback() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;

    if (!video) {
      return;
    }

    video.oncanplaythrough = () => {
      if (
        video.currentTime === MAGIC_NUMBERS['0'] &&
        (video.paused || video.ended)
      ) {
        video.muted = true;
        video.loop = true;
        video.autoplay = true;

        if (video.controls) {
          video.controls = false;
          video.setAttribute('controlsList', 'nodownload');
        }
        video.play().catch((err) => this.loggerService.displayLog(err));
      }
    };
  }

  startVideoPlayback() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;

    if (video) {
      video.muted = false;
      video.play().catch((err) => {
        this.loggerService.displayLog(err);
      });
    }
  }

  preventRightClick(event: MouseEvent): void {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
}
